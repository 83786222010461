import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { EstadosCidades } from "../../../utils/BrasilLocals";
import Api, { linkBackend } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card, Button } from "react-bootstrap";
import DateRangeInput from "../../../utils/DateRangeInput";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { convertDataProFusoCorreto } from "../../../utils/datesUtils";
import Select from "react-select";
import ButtonExportExcel from "../../../components/buttonExportListExcel";
import { FaTimes } from "react-icons/fa";

interface AcaoEngaja {
  acao: string;
  responsavel: string;
  status: string;
  dataPrazo: Date | undefined;
}

interface Acao {
  acao: string;
  responsavel: string;
  status: string;
  dt_prazo: Date | undefined;
}

interface Option {
  value: string;
  label: string;
}
const UNDEFINED_COLOR = process.env.REACT_APP_UNDEFINED_COLOR;
const RED_COLOR = process.env.REACT_APP_RED_COLOR;
const YELLOW_COLOR = process.env.REACT_APP_YELLOW_COLOR;
const GREEN_COLOR = process.env.REACT_APP_GREEN_COLOR;

interface GridEngajamentoDemandaProps {
  config: any;
  idDemanda: number;
}

const GridEngajamentoDemanda: React.FC<GridEngajamentoDemandaProps> = ({ config, idDemanda }) => {
  console.log("idDemanda iniciooo", idDemanda);

  const { t } = useTranslation();
  const navegar = useNavigate();
  const [datagridRows, setDatagridRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
  const [listaEstados, setListaEstados] = useState<any>([]);
  const [listaMunicipios, setListaMunicipios] = useState<any>([]);
  const [listaComunidades, setListaComunidades] = useState<any>([]);
  const [listaStakeholders, setListaStakeholders] = useState<any>([]);
  const [participantesExternosList, setParticipantesExternosList] =
    useState<any>([
      {
        id: 1,
        value: "",
      },
    ]);

  const [participantesMosaicList, setParticipantesMosaicList] = useState<any>([
    {
      id: 1,
      value: "",
    },
  ]);
  const [planoAcaoEngaja, setPlanoAcaoEngaja] = useState<boolean>(false);
  const [areasEnvolvidasEngaja, setAreasEnvolvidasEngaja] = useState<any>([]);
  const [acoesEngaja, setAcoesEngaja] = useState<AcaoEngaja[]>([
    { acao: "", responsavel: "", status: "", dataPrazo: undefined },
  ]);
  //input
  const [descricao, setDescricao] = useState<string>("");
  const [tema, setTema] = useState<string>("");
  const [stakeholder, setStakeholder] = useState<any>();

  const [assunto, setAssunto] = useState<string>("");
  const [formaDeContatoText, setFormaDeContatoText] = useState<string>("");
  const [fotoEngajamento, setFotoEngajamento] = useState(null);

  //Select
  const [categoria, setCategoria] = useState<string>("");
  const [formaDeContato, setFormaDeContato] = useState<string>("");
  const [criticidade, setCriticidade] = useState<string>("");
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");
  const [participantes, setParticipantes] = useState<string>("");

  //Date
  const [data, setData] = useState<any>();
  const [inicio, setInicio] = useState<string>("");
  const [previsao, setPrevisao] = useState<string>("");
  const [prazo, setPrazo] = useState<string>("");
  const [conclusao, setConclusao] = useState<string>("");

  const [origemEngaja, setOrigemEngaja] = useState<string>();
  const [temaEngaja, setTemaEngaja] = useState<string>();

  interface MunicipiosPorEstado {
    [estado: string]: string[];
  }
  const municipiosPorEstado: MunicipiosPorEstado = {};

  const [estadosExistentes, municipiosExistentes] = useMemo(() => {
    const estados: string[] = [];
    const municipios: string[] = [];

    datagridRows.forEach((r) => {
      r.estado && !estados.includes(r.estado) && estados.push(r.estado);
      r.municipio &&
        !municipios.includes(r.municipio) &&
        municipios.push(r.municipio);
    });

    return [
      estados.sort((a, b) => a.localeCompare(b)),
      municipios.sort((a, b) => a.localeCompare(b)),
    ];
  }, [datagridRows]);

  datagridRows.forEach((r) => {
    if (r.estado && r.municipio) {
      if (!municipiosPorEstado[r.estado]) {
        municipiosPorEstado[r.estado] = [];
      }

      if (!municipiosPorEstado[r.estado].includes(r.municipio)) {
        municipiosPorEstado[r.estado].push(r.municipio);
      }
    }
  });

  const [openModalFiltro, setOpenModalFiltro] = useState(false);
  const [filtroEstado, setFiltroEstado] = useState<string>();
  const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
  const [filtroContato, setFiltroContato] = useState<string>();
  const [filtroAssunto, setFiltroAssunto] = useState<string>();
  const [filtroAtual, setFiltroAtual] = useState<string>();
  const [filtroData, setFiltroData] = useState<DateRange>();
  // const [dadosFiltrados, setDadosFiltrados] = useState<any[]>([]);

  const [idEngajamento, setIdEngajamento] = useState<number>(0);
  const [showPickerData, setShowPickerData] = useState<string>();

  //input
  const [tarefa, setTarefa] = useState<string>("");
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

  const municipiosFiltrados = useMemo(() => {
    return filtroEstado
      ? municipiosPorEstado[filtroEstado] || []
      : municipiosExistentes;
  }, [filtroEstado, municipiosPorEstado, municipiosExistentes]);

  const tiposFiltro = [
    // {
    //   value: "Contato",
    //   label: t("Contato"),
    //   type: "string",
    //   filter: filtroContato,
    //   setFilter: setFiltroContato,
    // },
    {
      value: "Data",
      label: t("Data"),
      type: "date",
      filter: filtroData,
      setFilter: setFiltroData,
    },
    // {
    //   value: "Assunto",
    //   label: t("Assunto"),
    //   type: "string",
    //   filter: filtroAssunto,
    //   setFilter: setFiltroAssunto,
    // },
    {
      value: "Estado",
      label: t("Estado"),
      type: "select",
      opts: estadosExistentes,
      filter: filtroEstado,
      setFilter: setFiltroEstado,
    },
    {
      value: "Municipio",
      label: t("Municipio"),
      type: "select",
      opts: municipiosFiltrados,
      filter: filtroMunicipio,
      setFilter: setFiltroMunicipio,
    },
  ];

  const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

  const dadosFiltrados = useMemo(() => {
    const filteredData = datagridRows.filter((v) => {
      return (
        (!filtroContato ||
          v.contato?.toLowerCase().includes(filtroContato?.toLowerCase())) &&
        (!filtroAssunto ||
          v.assunto?.toLowerCase().includes(filtroAssunto?.toLowerCase())) &&
        (!filtroEstado ||
          v.estado?.toLowerCase().includes(filtroEstado?.toLowerCase())) &&
        (!filtroMunicipio ||
          v.municipio
            ?.toLowerCase()
            .includes(filtroMunicipio?.toLowerCase())) &&
        (!filtroData ||
          (v.data >= (filtroData.from || new Date()) &&
            v.data <=
            (filtroData.to ? filtroData.to : filtroData.from || new Date())))
      );
    });

    return filteredData;
  }, [
    datagridRows,
    filtroContato,
    filtroAssunto,
    filtroEstado,
    filtroMunicipio,
    filtroData,
  ]);

  const handleSubmitEdit = async () => {
    const formData = new FormData();
    if (fotoEngajamento !== null) {
      formData.append("avatar", fotoEngajamento);
    }
    formData.append("id", String(idEngajamento));
    formData.append("estado", estado);
    formData.append("municipio", municipio);
    formData.append("assunto", assunto);
    formData.append("descricao", descricao);
    formData.append("formaDeContato", formaDeContato);
    formData.append("stakeholder", stakeholder);
    formData.append("data", data);

    const participantesNameMosaic = participantesMosaicList.map((part: any) => part.value)
    const participantesNameExternos = participantesExternosList.map((part: any) => part.value)

    formData.append(
      "participantesMosaic",
      JSON.stringify(participantesNameMosaic)
    );

    formData.append(
      "participantesExternos",
      JSON.stringify(participantesNameExternos)
    );

    formData.append("planoAcao", String(planoAcaoEngaja));
    formData.append("areasEnvolvidas", JSON.stringify(areasEnvolvidasEngaja));
    formData.append("acoes", JSON.stringify(acoesEngaja));

    origemEngaja && formData.append("origem", origemEngaja);
    temaEngaja && formData.append("tema", temaEngaja);

    await Api.PutEngajamento(formData, config)
      .then((res) => {
        toast.success("Alterações salvas com sucesso!");
        closeModal();
        updateDatagrid();
      })
      .catch((err) => {
        toast.error("Não foi possível salvar as alterações!");
      });
  };

  useEffect(() => {
    if (selectedRowData) {
      setIdEngajamento(selectedRowData.id || 0);
      setFormaDeContato(selectedRowData.contato || "");
      setData(formatDate(selectedRowData.data) || "");
      setDescricao(selectedRowData.descricao || "");
      setEstado(selectedRowData.estado || "");
      setMunicipio(selectedRowData.municipio || "");
      if (selectedRowData.participantes_mosaic) {
        const newParticipantesMosaicList =
          selectedRowData.participantes_mosaic.map((part: any, i: number) => ({
            id: i,
            value: part,
          }));
        setParticipantesMosaicList(newParticipantesMosaicList);
      }

      if (selectedRowData.participantes_externos) {
        const newParticipantesExternosList =
          selectedRowData.participantes_externos.map(
            (part: any, i: number) => ({
              id: i,
              value: part,
            })
          );
        setParticipantesExternosList(newParticipantesExternosList);
      }

      setStakeholder(selectedRowData.stakeholder || "");
      setAssunto(selectedRowData.assunto || "");

      setTema(selectedRowData.tema || "");

      setAreasEnvolvidasEngaja(
        selectedRowData.areas_envolvidas
          ? JSON.parse(selectedRowData.areas_envolvidas)
          : []
      );
      const acoesObj = selectedRowData.acao
        ? JSON.parse(selectedRowData.acao)
        : [];
      const responsavelObj = selectedRowData.responsavel
        ? JSON.parse(selectedRowData.responsavel)
        : [];
      const statusObj = selectedRowData.status
        ? JSON.parse(selectedRowData.status)
        : [];
      const dataPrazoObj = selectedRowData.prazo
        ? JSON.parse(selectedRowData.prazo)
        : [];

      const objCompleto: AcaoEngaja[] = acoesObj.map(
        (acao: any, index: number) => ({
          acao,
          responsavel: responsavelObj[index],
          status: statusObj[index],
          dataPrazo: new Date(dataPrazoObj[index]),
        })
      );

      setAcoesEngaja(objCompleto);

      setPlanoAcaoEngaja(selectedRowData.plano_de_acao || false);

      setTarefa(selectedRowData.tarefas || "");
      setCategoria(selectedRowData.categoria || "");
      setCriticidade(selectedRowData.criticidade || "");
      setFotoEngajamento(selectedRowData.foto || null);
      setConclusao(selectedRowData.conclusao || "");

      setOrigemEngaja(selectedRowData.origem || "");
      setTemaEngaja(selectedRowData.tema || "");
    }
  }, [selectedRowData]);

  const updateDatagrid = useCallback(() => {
    if (!config || !navegar || !t) return;
    Api.getEngajamentosByDemanda(idDemanda)
      .then((data: any[]) => {
        const mappedData = data.map((d: any, index: number) => {
          const dataAjustada = d.data
            ? convertDataProFusoCorreto(d.data)
            : null;

          return {
            ...d,
            id: d.id,
            contato: d.contato,
            data: dataAjustada,
            descricao: d.descricao,
            estado: d.estado || "?",
            municipio: d.municipio || "?",
            participantes_mosaic: JSON.parse(d.participantes_mosaic),
            participantes_externos: JSON.parse(d.participantes_externos),
            stakeholder: JSON.parse(d.stakeholder),
            assunto: d.tema,
            foto: d.foto,
          };
        });

        const sortedData = mappedData.sort((a, b) => b.id - a.id);
        setDatagridRows(sortedData);
      })
      .catch((err: any) => {
        if (typeof err === "string") {
          toast.error(t(err));
          navegar("/login");
          return;
        }

        toast.error(t("Houve um erro ao carregar as informações"));
      });
  }, [config, navegar, t]);

  const handleRowClick = (params: any) => {
    setSelectedRowData(params.row);
    setIsModalOpen(true);
  };

  const datagridColunas: readonly GridColDef<any>[] = [
    // {
    //   field: "Stakeholder",
    //   type: "string",
    //   headerName: t("Stakeholder"),
    //   width: 200,
    // },
    {
      field: "estado",
      headerName: t("Estado") as string,
      type: "string",
      width: 120,
    },
    {
      field: "municipio",
      headerName: t("Municipio") as string,
      type: "select",
      width: 120,
    },
    {
      field: "contato",
      headerName: t("Contato") as string,
      type: "string",
      width: 150,
    },
    // {
    //   field: "assunto",
    //   headerName: t("Assunto") as string,
    //   type: "string",
    //   width: 170,
    // },
    {
      field: "descricao",
      headerName: t("Descrição") as string,
      type: "string",
      width: 170,
    },
    {
      field: "data",
      headerName: t("header_Data") as string,
      type: "date",
      width: 150,
      valueGetter: ({ row }: { row: any }) => {
        if (!row.data) {
          return null;
        }
        return new Date(row.data);
      },
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        return params.value && params.value instanceof Date
          ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
          : "";
      },
    },
    // {
    //   field: "data",
    //   headerName: t("Data") as string,
    //   type: "string",
    //   width: 150,
    // },
    {
      field: "participantes_mosaic",
      headerName: t("Participantes Mosaic") as string,
      type: "string",
      width: 200,
    },
    {
      field: "participantes_externos",
      type: "string",
      headerName: t("Participantes Exernos") as string,
      width: 200,
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateLocales = useCallback(() => {
    Api.GetLocales().then((data) => {
      setListaEstados(data.localidades.estados);
      setListaMunicipios(data.localidades.municipios);
    });

    Api.GetStakeholders(config).then((data) => {
      data ? setListaStakeholders(data) : setListaStakeholders([]);
    });
  }, []);

  useEffect(updateDatagrid, [updateDatagrid]);
  useEffect(updateLocales, [updateLocales]);

  const handleDelete = () => {
    Api.DeleteEngajamento(selectedRowData.id, config)
      .then((res) => {
        toast.success("Registro deletado com sucesso!");
        handleCloseModalDelete();
        closeModal();
        updateDatagrid();
      })
      .catch((err) => {
        toast.error("Não foi possível deletar o registro!");
        handleCloseModalDelete();
      });
  };

  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    setShowModalDelete(true);
  };

  const handleModalFiltros = (filtro: string) => {
    setFiltroAtivo((filtros) => {
      const newFiltros = [...filtros];
      const fIndex = newFiltros.findIndex((f) => f === filtro);
      if (fIndex >= 0) {
        newFiltros.splice(fIndex, 1);
        tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
      } else {
        newFiltros.push(filtro);
      }
      return newFiltros;
    });
    setOpenModalFiltro(true);
  };

  function formatDate(dateString: string | Date) {
    const dateOri = new Date(dateString);

    const date = new Date(
      dateOri.setHours(dateOri.getHours() + dateOri.getTimezoneOffset() / 60)
    );
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().padStart(4, "0");

    return `${year}-${month}-${day}`;
  }

  const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
    const handleViewImage = () => {
      const overlayElement = document.createElement("div");
      overlayElement.style.cssText = `
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
          `;

      let imgElement: HTMLImageElement | null = document.createElement("img");

      imgElement.onload = () => {
        if (imgElement && imgElement.complete) {
          overlayElement.appendChild(imgElement);
        } else {
          showNoImageMessage();
        }
      };

      imgElement.onerror = () => {
        showNoImageMessage();
      };

      const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
      const linkConcat = `${linkBackend}${formattedImageUrl}`;
      const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
      imgElement.src = linkCompleto;
      imgElement.alt = "Imagem";
      imgElement.style.cssText = `
          max-height: 80vh;
          max-width: 100%;
        `;

      const showNoImageMessage = () => {
        const noImageMessage = document.createElement("div");
        noImageMessage.textContent = "Nenhuma imagem disponível";
        noImageMessage.style.cssText = `
              color: white;
              font-size: 20px;
            `;

        overlayElement.appendChild(noImageMessage);
      };

      overlayElement.addEventListener("click", () => {
        document.body.removeChild(overlayElement);
      });

      document.body.appendChild(overlayElement);
    };

    return (
      <Col sm={12}>
        <Col sm={12}>
          <button
            onClick={handleViewImage}
            className="botao-grid"
            type="button"
          >
            {t("Visualizar foto")}
          </button>
        </Col>
      </Col>
    );
  };

  const handleChangeStakeholder = (value: any, index: any) => {
    const updatedStakeholders = [...stakeholder];
    updatedStakeholders[index] = value;
    setStakeholder(updatedStakeholders);
  };

  const getRangeText = (range: DateRange): string => {
    if (!range?.from) return "";

    return (
      format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
      " à " +
      format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
    );
  };

  const optionsAreas = [
    { value: "Operações", label: "Operações" },
    { value: "Projetos", label: "Projetos" },
    { value: "EHS", label: "EHS" },
    { value: "Jurídico", label: "Jurídico" },
    { value: "Tributário", label: "Tributário" },
    {
      value: "Relações com Comunidades",
      label: "Relações com Comunidades",
    },
    { value: "Financeiro", label: "Financeiro" },
    { value: "CSC", label: "CSC" },
    { value: "RH", label: "RH" },
    { value: "Comunicação", label: "Comunicação" },
    { value: "Relações Institucionais", label: "Relações Institucionais" },
    { value: "Relações Governamentais", label: "Relações Governamentais" },
    { value: "Instituto Mosaic", label: "Instituto Mosaic" },
  ];

  const handleAreasChange = (selectedOptions: any) => {
    const newOptions = (selectedOptions as Option[]).filter(
      (option) =>
        !areasEnvolvidasEngaja.some(
          (existingOption: any) => existingOption.value === option.value
        )
    );

    setAreasEnvolvidasEngaja((prev: any) => [...prev, ...newOptions]);
  };

  const handleChangeAcoes = (
    index: number,
    field: keyof AcaoEngaja,
    value: string | Date
  ) => {
    const novasAcoes = [...acoesEngaja];

    if (field === "dataPrazo") {
      novasAcoes[index][field] =
        typeof value === "string"
          ? new Date(value + "T00:00:00")
          : (value as Date);
    } else {
      novasAcoes[index][field] = value as string;
    }

    setAcoesEngaja(novasAcoes);
  };

  const adicionarCampoAcaoEngaja = () => {
    if (acoesEngaja.length >= 4) {
      toast.error("Limite de ações por engajamento atingido, maximo 4 ações")
    } else {
      setAcoesEngaja([
        ...acoesEngaja,
        { acao: "", responsavel: "", status: "", dataPrazo: undefined },
      ]);
    }
  };

  const removerCampoAcaoEngaja = (index: number) => {
    const novasAcoes = [...acoesEngaja];
    novasAcoes.splice(index, 1);
    setAcoesEngaja(novasAcoes);
  };

  const handleRemoveParticipanteMosaic = (id: number) => {
    const updatedParticipantesMosaic = participantesMosaicList.filter(
      (participanteMosaic: any) => participanteMosaic.id !== id
    );
    setParticipantesMosaicList(updatedParticipantesMosaic);
  };

  const handleAddParticipanteExternos = () => {
    const newParticipanteExternos = {
      id: participantesExternosList.length + 1,
      value: "",
    };
    setParticipantesExternosList([
      ...participantesExternosList,
      newParticipanteExternos,
    ]);
  };

  const handleRemoveParticipanteExternos = (id: number) => {
    const updatedParticipantesExternos = participantesExternosList.filter(
      (participanteExternos: any) => participanteExternos.id !== id
    );
    setParticipantesExternosList(updatedParticipantesExternos);
  };

  const handleAddParticipanteMosaic = () => {
    const newParticipanteMosaic = {
      id: participantesMosaicList.length + 1,
      value: "",
    };
    setParticipantesMosaicList([
      ...participantesMosaicList,
      newParticipanteMosaic,
    ]);
  };

  return (
    <Col sm={12}>
      <div className="grid-dados-relacionamento">
        <Col sm={12}>
          <Row>
            <Col sm={9}>
              {filtrosAtivos.map((filtro, i) => {
                const filterObj = tiposFiltro.find(
                  ({ value }: { value: any }) => value === filtro
                );
                if (!filterObj) return null;

                const { filter, setFilter, type, opts } = filterObj;

                return (
                  <Col className="filtro-ativo" key={i}>
                    <Row>
                      <Col sm={9}>
                        {type === "date" ? (
                          <>
                            <button
                              onClick={() => setShowPickerData(filtro)}
                              style={{
                                border: 0,
                                outline: 0,
                                backgroundColor: "#0000",
                                display: "block",
                                width: "100%",
                                height: "100%",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              <Form.Control
                                type="text"
                                placeholder={`Filtrar ${filtro}`}
                                value={getRangeText(filter as any)}
                                style={{ height: "73%" }}
                                onFocus={(e) => e.target.blur()}
                                readOnly
                              />
                            </button>
                            {showPickerData === filtro && (
                              <DateRangeInput
                                selected={filter as any}
                                onChange={setFilter}
                                onFinish={() => setShowPickerData(undefined)}
                              />
                            )}
                          </>
                        ) : type === "select" ? (
                          <Form.Select
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          >
                            <option label={`Filtrar ${filtro}`} value={""} />
                            {opts?.map((opt, i) => {
                              const isString = typeof opt === "string";

                              return (
                                <option
                                  key={i}
                                  label={isString ? opt : opt}
                                  value={isString ? opt : opt}
                                />
                              );
                            })}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder={`Filtrar ${filtro}`}
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          />
                        )}
                      </Col>
                      <Col sm={3}>
                        <button
                          onClick={() => {
                            setFilter(undefined);
                          }}
                          className="botao-filtro mt-2"
                        >
                          {t("Limpar Filtro")}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Col>
            <Col sm={3}>
              <button
                className="botao-filtro mt-2"
                onClick={() => setOpenModalFiltro(!openModalFiltro)}
              >
                {t("Filtros")}
              </button>
            </Col>
          </Row>

          {openModalFiltro && (
            <Col sm={12} className="mb-2">
              <Card>
                <Card.Body>
                  <Col sm={12} className="justify-content-center">
                    <Row>
                      {tiposFiltro.map((filtro, i) => (
                        <Col sm={3} key={i}>
                          <button
                            className={`filtros-modal ${filtrosAtivos?.includes(filtro.value)
                                ? "ativo"
                                : ""
                              }`}
                            onClick={() => {
                              handleModalFiltros(filtro.value);
                            }}
                          >
                            {filtro.label}
                          </button>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Col>

        <div>
          <DataGrid
            rows={dadosFiltrados}
            columns={datagridColunas}
            onRowClick={handleRowClick}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
          />
        </div>
        <div className="mt-2">
          <ButtonExportExcel lista={dadosFiltrados}></ButtonExportExcel>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        className="modal-xl"
        dialogClassName="modal-rollover"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Detalhes do Engajamento")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRowData && (
            <div id="content-grid">
              <Form
                // onSubmit={handleSubmitFormEngajamento}
                encType="multipart/form-data"
              >
                <Col sm={12} className="mt-4 mb-4">
                  <Row>
                    <Col sm={6}>
                      <Form.Label>{t("Estado")}</Form.Label>
                      <Form.Select
                        required
                        value={estado}
                        onChange={(e) => {
                          setEstado(e.target.value);
                        }}
                      >
                        <option value={""}>{t("Selecione o estado")}</option>
                        {listaEstados?.map((estado: any) => {
                          return (
                            <option value={estado.sigla}>{estado.nome}</option>
                          );
                        })}
                      </Form.Select>
                    </Col>

                    <Col sm={6}>
                      <Form.Label>{t("Municipio")}</Form.Label>
                      <Form.Select
                        required
                        value={municipio}
                        onChange={(e) => {
                          setMunicipio(e.target.value);
                        }}
                      >
                        <option value={""}>{t("Selecione o municipio")}</option>
                        {listaMunicipios[estado]?.map((municipio: any) => {
                          return <option value={municipio}>{municipio}</option>;
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>


                <Col sm={12} className="mt-4 mb-4">
                  <Row>
                    <Col sm={6}>
                      <Form.Label>{t("Origem")}</Form.Label>
                      <Form.Select
                        required
                        value={origemEngaja}
                        className="formSelect"
                      onChange={(e) => {
                        setOrigemEngaja(e.target.value);
                      }}
                      >
                        <option value={""}>
                          {t("Selecione Origem")}
                        </option>
                        <option
                          value={
                            "Poder Público Municipal - Prefeitura"
                          }
                        >
                          {t(
                            "Poder Público Municipal - Prefeitura"
                          )}
                        </option>
                        <option
                          value={
                            "Poder Público Municipal - Secretaria"
                          }
                        >
                          {t(
                            "Poder Público Municipal - Secretaria"
                          )}
                        </option>
                        <option
                          value={
                            "Poder Público Municipal - Autarquia"
                          }
                        >
                          {t(
                            "Poder Público Municipal - Autarquia"
                          )}
                        </option>
                        <option
                          value={
                            "Poder Público Municipal - Câmara de Vereadores"
                          }
                        >
                          {t(
                            "Poder Público Municipal - Câmara de Vereadores"
                          )}
                        </option>
                        <option value={"Poder Público Estadual"}>
                          {t("Poder Público Estadual")}
                        </option>
                        <option value={"Poder Público Federal"}>
                          {t("Poder Público Federal")}
                        </option>
                        <option value={"Ministério Público"}>
                          {t("Ministério Público")}
                        </option>
                        <option value={"Entidades Setoriais"}>
                          {t("Entidades Setoriais")}
                        </option>
                        <option
                          value={"Entidades do Terceiro Setor"}
                        >
                          {t("Entidades do Terceiro Setor")}
                        </option>
                        <option value={"Setor Privado"}>
                          {t("Setor Privado")}
                        </option>
                        <option value={"Academia"}>
                          {t("Academia")}
                        </option>
                        <option value={"outros"}>
                          {t("Outros")}
                        </option>
                      </Form.Select>
                    </Col>
                    <Col sm={6}>
                      <Form.Label>{t("Tema")}</Form.Label>
                      <Form.Control
                        required
                        className="formInput"
                        type={"text"}
                        value={temaEngaja}
                        onChange={(e) => {
                          setTemaEngaja(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>


                {formaDeContato === "Outros" ? (
                  <div>
                    <Col sm={12} className="mt-4 mb-4">
                      <Row>
                        <Col sm={6}>
                          <Form.Label>{t("Forma de contato")}</Form.Label>
                          <Form.Select
                            required
                            className="inputSelect"
                            onChange={(event) => {
                              setFormaDeContato(event.target.value);
                            }}
                          >
                            <option value="">{t("Selecione Formato")}</option>
                            <option value="Virtual">{t("Virtual")}</option>
                            <option value="Presencial">
                              {t("Presencial")}
                            </option>
                            <option value="Ligação Telefônica">
                              {t("Ligação Telefônica")}
                            </option>
                            <option value="Outros">{t("Outros")}</option>
                          </Form.Select>
                        </Col>

                        <Col sm={6}>
                          <Form.Label>{t("Data")}</Form.Label>
                          <Form.Control
                            required
                            className="formInput"
                            type={"date"}
                            value={data}
                            onChange={(e) => {
                              setData(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Col sm={12} className="mt-4">
                        <Form.Label>{t("Digite")}</Form.Label>
                        <Form.Control
                          required
                          value={formaDeContato}
                          className="formInput"
                          type="text"
                          placeholder="forma de contato"
                          onChange={(e) => {
                            setFormaDeContatoText(e.target.value);
                          }}
                        />
                      </Col>
                    </Col>
                    <Col sm={12} className="mt-4 mb-4">
                      <Row>
                        <Col sm={6}>
                          {participantesMosaicList
                            ? participantesMosaicList.map(
                              (part: any, i: any) => (
                                <div key={i} className="mt-4">
                                  <Row>
                                    <Col sm={10}>
                                      <Form.Label>
                                        {t(`Participante Mosaic ${i + 1}`)}
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        placeholder="Participantes Mosaic"
                                        className="formInput"
                                        value={part.value || ""}
                                        type="text"
                                        onChange={(event) => {
                                          const updatedParticipantesMosaicList =
                                            [...participantesMosaicList];
                                          updatedParticipantesMosaicList[
                                            i
                                          ].value = event.target.value;
                                          setParticipantesMosaicList(
                                            updatedParticipantesMosaicList
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col sm={2}>
                                      <button
                                        type="button"
                                        className="button-excluir-stake"
                                        onClick={() =>
                                          handleRemoveParticipanteMosaic(
                                            part.id
                                          )
                                        }
                                      >
                                        <FaTimes />
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              )
                            )
                            : null}
                        </Col>

                        <Col sm={6}>
                          {participantesExternosList
                            ? participantesExternosList.map(
                              (part: any, i: any) => (
                                <div key={i} className="mt-4">
                                  <Row>
                                    <Col sm={10}>
                                      <Form.Label>
                                        {t(`Participante Externo ${i + 1}`)}
                                      </Form.Label>
                                      <Form.Control
                                        placeholder="Participantes Externos"
                                        className="formInput"
                                        value={part.value || ""}
                                        type="text"
                                        onChange={(event) => {
                                          const updatedParticipantesExternosList =
                                            [...participantesExternosList];
                                          updatedParticipantesExternosList[
                                            i
                                          ].value = event.target.value;
                                          setParticipantesExternosList(
                                            updatedParticipantesExternosList
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col sm={2}>
                                      <button
                                        type="button"
                                        className="button-excluir-stake"
                                        onClick={() =>
                                          handleRemoveParticipanteExternos(
                                            part.id
                                          )
                                        }
                                      >
                                        <FaTimes />
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              )
                            )
                            : null}
                        </Col>
                      </Row>
                    </Col>
                  </div>
                ) : (
                  <div>
                    <Col sm={12} className="mt-4 mb-4">
                      <Row>
                        <Col sm={6}>
                          <Form.Label>{t("Forma de contato")}</Form.Label>
                          <Form.Select
                            className="inputSelect"
                            value={formaDeContato}
                            onChange={(event) => {
                              setFormaDeContato(event.target.value);
                            }}
                          >
                            <option value="">{t("Selecione Formato")}</option>
                            <option value="Virtual">{t("Virtual")}</option>
                            <option value="Presencial">
                              {t("Presencial")}
                            </option>
                            <option value="Ligação Telefônica">
                              {t("Ligação Telefônica")}
                            </option>
                            <option value="Outros">{t("Outros")}</option>
                          </Form.Select>
                        </Col>
                        <Col sm={6}>
                          <Form.Label>{t("Data")}</Form.Label>
                          <Form.Control
                            required
                            className="formInput"
                            type={"date"}
                            value={data}
                            onChange={(e) => {
                              setData(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={12} className=" mb-4">
                      <Row>
                        <Col sm={6}>
                          {participantesMosaicList
                            ? participantesMosaicList.map(
                              (part: any, i: any) => (
                                <div key={i} className="mt-4">
                                  <Row>
                                    <Col sm={10}>
                                      <Form.Label>
                                        {t(`Participante Mosaic ${i + 1}`)}
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        placeholder="Participantes Mosaic"
                                        className="formInput"
                                        value={part.value || ""}
                                        type="text"
                                        onChange={(event) => {
                                          const updatedParticipantesMosaicList =
                                            [...participantesMosaicList];
                                          updatedParticipantesMosaicList[
                                            i
                                          ].value = event.target.value;
                                          setParticipantesMosaicList(
                                            updatedParticipantesMosaicList
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col sm={2}>
                                      <button
                                        type="button"
                                        className="button-excluir-stake"
                                        onClick={() =>
                                          handleRemoveParticipanteMosaic(
                                            part.id
                                          )
                                        }
                                      >
                                        <FaTimes />
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              )
                            )
                            : null}
                        </Col>

                        <Col sm={6}>
                          {participantesExternosList
                            ? participantesExternosList.map(
                              (part: any, i: any) => (
                                <div key={i} className="mt-4">
                                  <Row>
                                    <Col sm={10}>
                                      <Form.Label>
                                        {t(`Participante Externo ${i + 1}`)}
                                      </Form.Label>
                                      <Form.Control
                                        placeholder="Participantes Externos"
                                        className="formInput"
                                        value={part.value || ""}
                                        type="text"
                                        onChange={(event) => {
                                          const updatedParticipantesExternosList =
                                            [...participantesExternosList];
                                          updatedParticipantesExternosList[
                                            i
                                          ].value = event.target.value;
                                          setParticipantesExternosList(
                                            updatedParticipantesExternosList
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col sm={2}>
                                      <button
                                        type="button"
                                        className="button-excluir-stake"
                                        onClick={() =>
                                          handleRemoveParticipanteExternos(
                                            part.id
                                          )
                                        }
                                      >
                                        <FaTimes />
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              )
                            )
                            : null}
                        </Col>
                      </Row>
                    </Col>
                  </div>
                )}
                <Col sm={12} className="mt-4 mb-4">
                  <Row>
                    <Col sm={6}>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        style={{ width: "100%" }}
                        onClick={handleAddParticipanteMosaic}
                      >
                        {t("Adicionar Participantes Mosaic")}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        style={{ width: "100%" }}
                        onClick={handleAddParticipanteExternos}
                      >
                        {t("Adicionar Participantes Externo")}
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col sm={12} className="mb-4">
                  <Row>
                    <Col sm={8}>
                      <Form.Label>{t("Resumo Engajamento")}</Form.Label>
                      <Form.Control
                        required
                        placeholder="Resumo sobre Engajamento"
                        className="formInput"
                        maxLength={200}
                        as="textarea"
                        type={"text"}
                        value={descricao}
                        onChange={(e) => {
                          setDescricao(e.target.value);
                        }}
                      />
                    </Col>

                    <Col sm={4}>
                      <Form.Check
                        className="formSwitch"
                        type="switch"
                        style={{ marginTop: "6%", marginLeft: "5%" }}
                        label={t("Plano de Ação?")}
                        checked={planoAcaoEngaja}
                        onChange={(e) => {
                          setPlanoAcaoEngaja(e.target.checked);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                {planoAcaoEngaja && (
                  <div>
                    <Col sm={12} className="mt-4 mb-4">
                      <Row>
                        <Col sm={12}>
                          <Form.Label>{t("Áreas Envolvidas")}</Form.Label>
                          <Form.Group as={Row}>
                            <Col>
                              <Row>
                                <Select
                                  isMulti
                                  options={optionsAreas}
                                  value={areasEnvolvidasEngaja}
                                  onChange={handleAreasChange}
                                />
                              </Row>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>

                    <div>
                      <Col sm={12} className="mt-4 mb-4">
                        <Row>
                          {acoesEngaja &&
                            acoesEngaja.map((acao: any, index: any) => (
                              <div key={index}>
                                <Col sm={12} className="mt-4 mb-4">
                                  <Row>
                                    <Col sm={6}>
                                      <Form.Label>
                                        {t(`Ação ${index + 1}`)}
                                      </Form.Label>
                                      <Form.Control
                                        className="formInput"
                                        type="text"
                                        value={acao.acao && acao.acao}
                                        onChange={(e) =>
                                          handleChangeAcoes(
                                            index,
                                            "acao",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col sm={6}>
                                      <Form.Label>
                                        {t("Responsável")}
                                      </Form.Label>
                                      <Form.Control
                                        className="formInput"
                                        type="text"
                                        value={acao.responsavel}
                                        onChange={(e) =>
                                          handleChangeAcoes(
                                            index,
                                            "responsavel",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm={12} className="mt-4 mb-4">
                                  <Row>
                                    <Col sm={6}>
                                      <Form.Label>{t("Prazo")}</Form.Label>
                                      <Form.Control
                                        className="formInput"
                                        type="date"
                                        required
                                        value={
                                          (acao.dataPrazo
                                            ? new Date(acao.dataPrazo)
                                              .toISOString()
                                              .split("T")[0]
                                            : "") || ""
                                        }
                                        onChange={(e) =>
                                          handleChangeAcoes(
                                            index,
                                            "dataPrazo",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col sm={6}>
                                      <Form.Label>{t("Status")}</Form.Label>
                                      <Form.Select
                                        className="formSelect"
                                        value={acao.status}
                                        onChange={(e) =>
                                          handleChangeAcoes(
                                            index,
                                            "status",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option>
                                          {t("Selecionar Status")}
                                        </option>
                                        <option>{t("Não Iniciado")}</option>
                                        <option>{t("Em Andamento")}</option>
                                        <option>{t("Concluído")}</option>
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm={12}>
                                  {index >= 0 && (
                                    <Row>
                                      <Col sm={12}>
                                        <Button
                                          style={{ width: "100%" }}
                                          variant="danger mt-2 mb-3"
                                          onClick={() =>
                                            removerCampoAcaoEngaja(index)
                                          }
                                        >
                                          Remover Ação
                                        </Button>
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </div>
                            ))}
                        </Row>
                        <Col sm={12}>
                          <button
                            className="btn btn-primary"
                            style={{ width: "100%" }}
                            type="button"
                            onClick={adicionarCampoAcaoEngaja}
                          >
                            {t("Adicionar Ação")}
                          </button>
                        </Col>
                      </Col>
                    </div>
                  </div>
                )}
                <Col sm={12}>
                  <Row>
                    <Col sm={6}>
                      <Form.Label>{t("Selecione foto/documento")}</Form.Label>
                      <div className="cadastroFotos" style={{ padding: "1%" }}>
                        <Form.Control
                          type="file"
                          name="avatar"
                          multiple
                          className="inputFoto"
                          style={{ fontSize: "100%" }}
                          onChange={(e: any) => {
                            setFotoEngajamento(
                              e.target.files[0] ? e.target.files[0] : null
                            );
                          }}
                        />
                      </div>
                    </Col>

                    <Col sm={6}>
                      <ExibirImagem imageUrl={selectedRowData.foto} />
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={6}>
                      <button
                        className="botao-grid"
                        onClick={handleSubmitEdit}
                        type="button"
                      >
                        {t("Salvar")}
                      </button>
                    </Col>
                    <Col sm={6}>
                      <button
                        type="button"
                        className="botao-grid"
                        onClick={handleOpenModalDelete}
                      >
                        {t("Excluir")}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* MODAL CONFIRMACÇÂO DE EXCLUSAO */}
      <Modal
        show={showModalDelete}
        onHide={handleCloseModalDelete}
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div id="modalLogin">
            <form className="form">
              <p className="titleForgotPassword">
                {t("Deseja Excluir Engajamento?")}
              </p>

              <button
                className="botao-grid"
                type="button"
                onClick={handleDelete}
              >
                {t("Sim")}
              </button>
              <button
                className="botao-grid"
                type="button"
                onClick={handleCloseModalDelete}
              >
                {t("Não")}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

export default GridEngajamentoDemanda;
