import "./styles.css";
import Modal from "react-bootstrap/Modal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    DBUser,
    TemplateBody,
    TemplateFooter,
    TemplateHeader,
    TemplateHeaderFormats,
    TemplateItem,
    WppContact,
} from "./types";
import {
    IoTrash,
    // IoPencil,
    IoAdd,
    IoClose,
    IoCaretDown,
} from "react-icons/io5";
import WppApi from "./wppApi";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import WppToastError from "./wppToastError";
import Api, { ClearSessionStorage } from "../../config/api";
import { useNavigate } from "react-router-dom";
import PdfThumbnail from "../../components/pdfThumbnail";


function styleText(text: string) {
    text = text.replace(/\n/g, "<br />");
    text = text
        .split("*")
        .map((t, i, a) => (i % 2 === 1 && i !== a.length - 1 ? `<b>${t}</b>` : t))
        .join("");
    return text;
}

function normalize(str: string | undefined | null) {
    return str
        ?.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
}

async function getFileURL(file: string | File) {
    if (typeof file === "string") return file;

    const url: string = await new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const imageUrl = event.target?.result;

            if (typeof imageUrl !== "string") {
                rej(new Error("Arquivo não convertido para string"));
                return;
            }

            res(imageUrl);
        };
        reader.readAsDataURL(file);
    });

    return url;
}

function getAcceptFileType(headerType: TemplateHeaderFormats) {
    switch (headerType) {
        case "IMAGE":
            return "image/jpeg,image/jpg,image/png";
        case "VIDEO":
            return "video/mp4";
        case "DOCUMENT":
            return ".pdf";
    }
}

function MessageSimulator({
    headerType,
    headerText,
    headerFile,
    bodyText,
    footerText,
}: {
    headerType?: TemplateHeaderFormats;
    headerText?: string;
    headerFile?: File | string;
    bodyText: string;
    footerText: string;
}) {
    const { t } = useTranslation();

    const [file, setFile] = useState<string>();

    useEffect(() => {
        if (headerFile) getFileURL(headerFile).then(setFile);
        else setFile(undefined);
    }, [headerFile]);

    return (
        <div className="messageSimulatorPreview">
            <div className="messageSimulatorBaloon">
                {headerType === "IMAGE" && file && (
                    <img
                        className="messageSimulatorPreviewHeaderImg"
                        src={file}
                        alt={t("Header Foto") as string}
                    />
                )}

                {headerType === "VIDEO" && file && (
                    <video
                        className="messageSimulatorPreviewHeaderVideo"
                        src={file}
                        controls={true}
                    />
                )}

                {headerType === "DOCUMENT" && file && (
                    <div className="messageSimulatorPreviewHeaderDocument">
                        <PdfThumbnail file={file} />
                    </div>
                )}

                <div className="messagesSimulatorPreviewTexts">
                    {headerType === "TEXT" && headerText && (
                        <span
                            className="messageSimulatorPreviewHeaderText"
                            dangerouslySetInnerHTML={{ __html: styleText(headerText) }}
                        />
                    )}
                    <span
                        className="messageSimulatorPreviewBodyText"
                        dangerouslySetInnerHTML={{ __html: styleText(bodyText) }}
                    />
                    {footerText && (
                        <span
                            className="messageSimulatorPreviewFooterText"
                            dangerouslySetInnerHTML={{ __html: styleText(footerText) }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function SendMessageConfirmation({
    template,
    contacts,
    show,
    setShow,
    confirmSubmit,
}: {
    template: TemplateItem;
    contacts: WppContact[];
    show: boolean;
    setShow(s: boolean): void;
    confirmSubmit(): void;
}) {
    const { t } = useTranslation();

    const [confirmClick, setConfirmClick] = useState(false);

    const [loading, setLoading] = useState(false);

    const submitMessage = async () => {
        try {
            setLoading(true);

            await WppApi.postMessage(template, contacts);

            confirmSubmit();
        } catch (err) {
            if (err instanceof AxiosError) {
                const errTitle = err?.response?.data?.title;
                const errMessage = err?.response?.data?.message;
                return toast.error(<WppToastError errTitle={errTitle} errMessage={errMessage} />);
            }

            console.error(err);
            toast.error(t("Erro inesperado"));
        } finally {
            setLoading(false);
        }
    };

    const confirm = () => {
        if (!confirmClick) return setConfirmClick(true);
        submitMessage();
    };

    const dismiss = () => {
        setConfirmClick(false);
    };

    const sortedContacts = useMemo(
        () => contacts.sort((a, b) => a.nome.localeCompare(b.nome)),
        [contacts]
    );

    return (
        <Modal
            id="sendMessageConfirmationModal"
            show={show}
            onHide={() => setShow(false)}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("Confirmar envio: ", { template: template.name })}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="sendMessageConfirmationBody">
                <Modal.Title className="sendMessageConfirmationBodyTitle">
                    {t("Contatos Selecionados")}
                </Modal.Title>

                <div className="sendMessageConfirmationContacts">
                    {sortedContacts.map((c) => (
                        <span key={c.telefone} className="sendMessageConfirmationContactItem">
                            {c.nome} - {c.telefone}
                        </span>
                    ))}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => setShow(false)} variant="secondary">
                    {t("Cancelar")}
                </Button>
                <Button
                    onClick={confirm}
                    variant={loading ? "info" : confirmClick ? "warning" : "primary"}
                    onBlur={dismiss}
                    className="sendMessageConfirmationSubmit"
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <Spinner animation="border" role="status" size="sm" />
                            <span>{t("Enviando...")}</span>
                        </>
                    ) : confirmClick ? (
                        t("Clique novamente para confirmar o envio")
                    ) : (
                        t("Enviar")
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ModalCreateTemplate({ submit: parentSubmit }: { submit: () => void }) {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const [templateName, setTemplateName] = useState("");

    const [headerType, setHeaderType] = useState<TemplateHeaderFormats>();
    const [headerText, setHeaderText] = useState<string>("");
    const [headerFile, setHeaderFile] = useState<File>();

    const [bodyText, setBodyText] = useState<string>("");

    const [footerText, setFooterText] = useState<string>("");

    const headerTypeOptions: TemplateHeaderFormats[] = ["TEXT", "IMAGE", "VIDEO", "DOCUMENT"];

    const resetModal = useCallback(() => {
        setTemplateName("");
        setHeaderType(undefined);
        setHeaderFile(undefined);
        setHeaderText("");
        setBodyText("");
        setFooterText("");
    }, []);

    const submitCreate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (headerType === "TEXT" && !headerText) {
            return toast.info(
                t("O texto do cabeçalho é obrigatório para o tipo: ", { type: headerType })
            );
        }

        if (
            (headerType === "IMAGE" || headerType === "VIDEO" || headerType === "DOCUMENT") &&
            !headerFile
        ) {
            return toast.info(
                t("O arquivo do cabeçalho é obrigatório para o tipo: ", { type: headerType })
            );
        }

        if (!bodyText) {
            return toast.error(t("O texto do corpo é obrigatório"));
        }

        try {
            await WppApi.postTemplate(
                templateName,
                headerType,
                headerText,
                headerFile,
                bodyText,
                footerText
            );

            setShowModal(false);
            resetModal();
            parentSubmit();
        } catch (err) {
            if (err instanceof AxiosError) {
                const errTitle = err?.response?.data?.title;
                const errMessage = err?.response?.data?.message;

                if (errTitle || errMessage) {
                    return toast.error(
                        <WppToastError errTitle={errTitle} errMessage={errMessage} />
                    );
                }
            }

            console.error(err);
            toast.error(t("Erro inesperado"));
        }
    };

    useEffect(() => {
        setHeaderFile(undefined);
    }, [headerType]);

    return (
        <>
            <Button onClick={() => setShowModal(true)} className="templateCreateBtn">
                <IoAdd className="templateCreateIcon" />
                <span>{t("Novo Modelo")}</span>
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
                <Form onSubmit={submitCreate}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Novo Modelo")}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body id="whatsappPageModalBody">
                        <Row>
                            <Col md={8}>
                                <FloatingLabel label={t("Nome do Modelo")}>
                                    <Form.Control
                                        type="text"
                                        value={templateName}
                                        onChange={(e) => setTemplateName(e.target.value)}
                                        placeholder={t("Nome do Modelo") as string}
                                    />
                                </FloatingLabel>

                                <Modal.Title className="whatsappPageModalBodyTitle">
                                    {t("Cabeçalho")}
                                </Modal.Title>

                                <FloatingLabel label={t("Tipo do cabeçalho")}>
                                    <Form.Select
                                        value={headerType}
                                        onChange={(e) =>
                                            setHeaderType(e.target.value as typeof headerType)
                                        }
                                        // placeholder={t("Tipo do cabeçalho") as string}
                                    >
                                        <option
                                            value={undefined}
                                            label={t("Sem cabeçalho") as string}
                                        />

                                        {headerTypeOptions.map((ht) => (
                                            <option key={ht} value={ht} label={ht} />
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>

                                {headerType === "TEXT" && (
                                    <FloatingLabel label={t("Texto do cabeçalho")}>
                                        <Form.Control
                                            type="text"
                                            value={headerText}
                                            onChange={(e) => {
                                                const text = e.target.value;
                                                if (text.length > 60) return;
                                                setHeaderText(text);
                                            }}
                                            placeholder={t("Texto do cabeçalho") as string}
                                        />
                                    </FloatingLabel>
                                )}

                                {(headerType === "IMAGE" ||
                                    headerType === "VIDEO" ||
                                    headerType === "DOCUMENT") && (
                                    <Form.Group>
                                        <Form.Label>{t("Arquivo do cabeçalho")}</Form.Label>

                                        <Form.Control
                                            key={headerType}
                                            type="file"
                                            multiple
                                            onChange={(e: any) => {
                                                console.log("e.target.files[0]", e.target.files[0])
                                                console.log("e.target.files[1]", e.target.files[1])

                                                setHeaderFile(
                                                    e.target.files[0]
                                                        ? e.target.files[0]
                                                        : undefined
                                                );
                                            }}
                                            placeholder={t("Arquivo do cabeçalho") as string}
                                            accept={getAcceptFileType(headerType)}
                                        />

                                        {/* {headerType === "IMAGE" && (
                                            <Form.Control
                                            
                                            key={headerType}
                                            type="file"
                                            onChange={(e: any) => {
                                                setHeaderFile(
                                                    e.target.files[0]
                                                        ? e.target.files[0]
                                                        : undefined
                                                );
                                            }}
                                            placeholder={t("Arquivo do cabeçalho") as string}
                                            accept={getAcceptFileType(headerType)}
                                        />
                                        )} */}
                                    </Form.Group>
                                )}

                                <Modal.Title className="whatsappPageModalBodyTitle">
                                    {t("Corpo")}
                                </Modal.Title>

                                <FloatingLabel label={t("Texto do corpo")}>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={8}
                                        value={bodyText}
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            if (text.length > 1024) return;
                                            setBodyText(text);
                                        }}
                                        placeholder={t("Texto do corpo") as string}
                                        className="textArea"
                                    />
                                </FloatingLabel>

                                <Modal.Title className="whatsappPageModalBodyTitle">
                                    {t("Rodapé")}
                                </Modal.Title>

                                <FloatingLabel label={t("Texto do rodapé")}>
                                    <Form.Control
                                        type="text"
                                        value={footerText}
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            if (text.length > 60) return;
                                            setFooterText(text);
                                        }}
                                        placeholder={t("Texto do rodapé") as string}
                                    />
                                </FloatingLabel>
                            </Col>

                            <Col md={4}>
                                <MessageSimulator
                                    headerType={headerType}
                                    headerText={headerText}
                                    headerFile={headerFile}
                                    bodyText={bodyText}
                                    footerText={footerText}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            {t("Criar")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

function ModalCustomContact({
    confirmSubmit,
}: {
    confirmSubmit: (newContact: WppContact) => void;
}) {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const [nome, setNome] = useState("");
    const [telefone, setTelefone] = useState("");

    const submitCreate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!nome || !telefone) {
            return toast.info(t("Nome e telefone são obrigatórios"));
        }

        setShowModal(false);
        confirmSubmit({ nome, telefone });
    };

    return (
        <>
            <Button
                variant="light"
                className="selectContactsBlockHeaderButton"
                onClick={() => setShowModal((s) => !s)}
            >
                <IoAdd className="selectContactsBlockHeaderIcon" />
                <span>{t("Contato Personalizado")}</span>
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)} size="sm">
                <Form onSubmit={submitCreate}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Contato Personalizado")}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body id="whatsappPageModalBody">
                        <FloatingLabel label={t("Nome do Contato")}>
                            <Form.Control
                                type="text"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                placeholder={t("Nome do Contato") as string}
                            />
                        </FloatingLabel>

                        <FloatingLabel label={t("Telefone do Contato")}>
                            <Form.Control
                                type="text"
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                                placeholder={t("Telefone do Contato") as string}
                            />
                        </FloatingLabel>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            {t("Cancelar")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {t("Adicionar")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

function TemplateCard({
    template,
    select,
    selected,
}: {
    template: TemplateItem;
    select(item: TemplateItem): void;
    selected: boolean;
}) {
    const { t } = useTranslation();

    const submitDelete = async () => {
        if (!template.id) return toast.error(t("Erro ao identificar o template a ser apagado"));

        const confirmText = t("Você está prestes a deletar o template", {
            template: template.name,
        });
        const confirmName = window.prompt(confirmText);
        if (confirmName !== template.name) return;

        await WppApi.deleteTemplate(template.id, template.name);
    };

    // const submitEdit = async () => {
    //     const confirmName = window.prompt(
    //         `Você está prestes a deletar o template "${template.name}".\n\nPara confirmar, digite novamente o nome do template no campo abaixo.`
    //     );
    //     if (confirmName !== template.name) return;
    // };

    return (
        <div className="templateCardHolder">
            <div className={`templateCard  ${selected && "selected"}`}>
                <span className="templateName">{template.name}</span>
                <span className={`templateStatus ${template.status!.toLocaleLowerCase()}`}>
                    {template.status}
                </span>
                {/* <button className="templateEditBtn" onClick={submitEdit}>
                    <IoPencil />
                </button> */}
                <button className="templateDeleteBtn" onClick={submitDelete}>
                    <IoTrash />
                </button>
            </div>
            <button
                className="templateCardBack"
                onClick={() => select(template)}
                disabled={template.status !== "APPROVED"}
            >
                <span>
                    {template.status === "APPROVED" ? t("Usar Modelo") : t("Modelo não aprovado")}
                </span>
            </button>
        </div>
    );
}

function SelectContacts({
    users,
    setContacts,
    reset,
    setReset,
}: {
    users: DBUser[];
    setContacts(contacts: WppContact[]): void;
    reset: boolean;
    setReset(r: boolean): void;
}) {
    const { t } = useTranslation();

    const [selected, setSelected] = useState<WppContact[]>([]);

    const [filterUnselectedName, setFilterUnselectedName] = useState("");
    const [filterUnselectedPhone, setFilterUnselectedPhone] = useState("");
    const [filterUnselectedState, setFilterUnselectedState] = useState("");
    const [filterUnselectedCity, setFilterUnselectedCity] = useState("");

    const [filterSelectedName, setFilterSelectedName] = useState("");
    const [filterSelectedPhone, setFilterSelectedPhone] = useState("");
    const [filterSelectedState, setFilterSelectedState] = useState("");
    const [filterSelectedCity, setFilterSelectedCity] = useState("");

    const selectUser = useCallback(
        (u: DBUser | WppContact) => {
            if (!u.telefone) {
                toast.error(t("Esse usuário não possui telefone cadastrado"));
                return;
            }

            setSelected([
                { nome: u.nome, telefone: u.telefone!, estado: u.estado, municipio: u.municipio },
                ...selected,
            ]);
        },
        [selected, t]
    );

    const unselectUser = useCallback(
        (c: WppContact) => {
            setSelected([...selected.filter((s) => s.telefone !== c.telefone)]);
        },
        [selected]
    );

    const checkFilters = useCallback(
        (contact: DBUser | WppContact, fName = "", fPhone = "", fState = "", fCity = "") =>
            (!fName || normalize(contact.nome)?.includes(normalize(fName)!)) &&
            (!fPhone || normalize(contact.telefone)?.includes(normalize(fPhone)!)) &&
            (!fState || normalize(contact.estado)?.includes(normalize(fState)!)) &&
            (!fCity || normalize(contact.municipio)?.includes(normalize(fCity)!)),
        []
    );

    const resetAll = useCallback(() => {
        setSelected([]);
        setFilterUnselectedName("");
        setFilterUnselectedPhone("");
        setFilterUnselectedState("");
        setFilterUnselectedCity("");
        setFilterSelectedName("");
        setFilterSelectedPhone("");
        setFilterSelectedState("");
        setFilterSelectedCity("");
    }, []);

    const unselectedFiltered = useMemo(
        () =>
            users.filter((u) =>
                checkFilters(
                    u,
                    filterUnselectedName,
                    filterUnselectedPhone,
                    filterUnselectedState,
                    filterUnselectedCity
                )
            ),
        [
            users,
            checkFilters,
            filterUnselectedName,
            filterUnselectedPhone,
            filterUnselectedState,
            filterUnselectedCity,
        ]
    );

    const selectedFiltered = useMemo(
        () =>
            selected.filter((s) =>
                checkFilters(
                    s,
                    filterSelectedName,
                    filterSelectedPhone,
                    filterSelectedState,
                    filterSelectedCity
                )
            ),
        [
            selected,
            checkFilters,
            filterSelectedName,
            filterSelectedPhone,
            filterSelectedState,
            filterSelectedCity,
        ]
    );

    useEffect(() => {
        setContacts(selected);
    }, [setContacts, selected]);

    useEffect(() => {
        if (reset) {
            resetAll();
            setReset(false);
        }
    }, [resetAll, setReset, reset]);

    return (
        <div className="selectContacts">
            <div className="selectContactsBlock">
                <div className="selectContactsBlockHeader">
                    <h4>{t("Contatos")}</h4>
                </div>

                <div className="selectContactsFilters">
                    <input
                        value={filterUnselectedName}
                        onChange={(e) => setFilterUnselectedName(e.target.value)}
                        placeholder={t("Procurar Nome") as string}
                    />
                    <input
                        value={filterUnselectedPhone}
                        onChange={(e) => setFilterUnselectedPhone(e.target.value)}
                        placeholder={t("Procurar Telefone") as string}
                    />
                    <input
                        value={filterUnselectedState}
                        onChange={(e) => setFilterUnselectedState(e.target.value)}
                        placeholder={t("Procurar Estado") as string}
                    />
                    <input
                        value={filterUnselectedCity}
                        onChange={(e) => setFilterUnselectedCity(e.target.value)}
                        placeholder={t("Procurar Município") as string}
                    />
                </div>

                <div className="selectContactsList">
                    {unselectedFiltered.map((u) => {
                        const info = [u.nome, u.telefone, u.estado, u.municipio].filter((d) => !!d);
                        return (
                            <button
                                key={u.id}
                                onClick={() => selectUser(u)}
                                disabled={!!selected.find((s) => u.telefone === s.telefone)}
                            >
                                {info.join(" - ")}
                            </button>
                        );
                    })}
                </div>
            </div>

            <div className="selectContactsBlock">
                <div className="selectContactsBlockHeader">
                    <h4>{t("Selecionados")}</h4>

                    <ModalCustomContact confirmSubmit={selectUser} />
                </div>

                <div className="selectContactsFilters">
                    <input
                        value={filterSelectedName}
                        onChange={(e) => setFilterSelectedName(e.target.value)}
                        placeholder={t("Procurar Nome") as string}
                    />
                    <input
                        value={filterSelectedPhone}
                        onChange={(e) => setFilterSelectedPhone(e.target.value)}
                        placeholder={t("Procurar Telefone") as string}
                    />
                    <input
                        value={filterSelectedState}
                        onChange={(e) => setFilterSelectedState(e.target.value)}
                        placeholder={t("Procurar Estado") as string}
                    />
                    <input
                        value={filterSelectedCity}
                        onChange={(e) => setFilterSelectedCity(e.target.value)}
                        placeholder={t("Procurar Município") as string}
                    />
                </div>

                <div className="selectContactsList">
                    {selectedFiltered.map((s) => {
                        const info = [s.nome, s.telefone, s.estado, s.municipio].filter((d) => !!d);
                        return (
                            <button key={s.telefone} onClick={() => unselectUser(s)}>
                                {info.join(" - ")}
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function ModalSelectedTemplate({
    template,
    users,
    isSelected,
    closeModal,
}: {
    template: TemplateItem;
    users: DBUser[];
    isSelected: boolean;
    closeModal(): void;
}) {
    const { t } = useTranslation();

    const header = template.components.find((c) => c.type === "HEADER") as
        | TemplateHeader
        | undefined;
    const body = template.components.find((c) => c.type === "BODY") as TemplateBody | undefined;
    const footer = template.components.find((c) => c.type === "FOOTER") as
        | TemplateFooter
        | undefined;

    const headerText = header?.format === "TEXT" ? header.text : undefined;
    const headerFile =
        header?.format === "IMAGE" || header?.format === "DOCUMENT" || header?.format === "VIDEO"
            ? header.example?.header_handle[0]
            : undefined;
    const bodyText = body?.text || "";
    const footerText = footer?.text || "";

    const [showPreview, setShowPreview] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);

    const [contacts, setContacts] = useState<WppContact[]>([]);
    const [resetContacts, setResetContacts] = useState(false);

    const openSendMessage = async () => {
        if (contacts.length <= 0) {
            return toast.error(t("Selecione pelo menos 1 contato para enviar a mensagem"));
        }

        setShowConfirm(true);
    };

    const confirmMessageSent = () => {
        setResetContacts(true);
        setShowPreview(true);
        setShowConfirm(false);
        setContacts([]);
        closeModal();
    };

    return (
        <div className={`templateWindow ${isSelected ? "show" : ""}`}>
            <div className="templateWindowHeader">
                <h2 className="templateWindowTitle">{template.name}</h2>

                <button className="templateWindowClose" onClick={closeModal}>
                    <IoClose />
                </button>
            </div>

            <div className="templateWindowContent">
                <button
                    className="templateWindowSimulatorToggle"
                    onClick={() => setShowPreview((s) => !s)}
                >
                    <IoCaretDown
                        className={`templateWindowSimulatorCaret ${showPreview ? "down" : "up"}`}
                    />
                    {showPreview ? t("Esconder Modelo") : t("Mostrar Modelo")}
                </button>

                <div className={`templateWindowSimulator ${showPreview ? "show" : ""}`}>
                    <MessageSimulator
                        headerType={header?.format}
                        headerText={headerText}
                        headerFile={headerFile}
                        bodyText={bodyText}
                        footerText={footerText}
                    />
                </div>

                <hr />

                <div className="templateWindowForm">
                    <div className="templateWindowFormHeader">
                        <h3 className="templateWindowFormTitle">{t("Enviar Mensagem")}</h3>

                        <Button
                            onClick={openSendMessage}
                            className="templateWindowFormSubmit"
                            variant="success"
                        >
                            {t("Enviar para os contatos selecionados")}
                        </Button>
                    </div>

                    <hr />

                    <SelectContacts
                        users={users}
                        setContacts={setContacts}
                        reset={resetContacts}
                        setReset={setResetContacts}
                    />
                </div>
            </div>

            {isSelected && contacts.length > 0 && (
                <SendMessageConfirmation
                    show={showConfirm}
                    setShow={setShowConfirm}
                    template={template}
                    contacts={contacts}
                    confirmSubmit={confirmMessageSent}
                />
            )}
        </div>
    );
}

function Whatsapp() {
    const navegar = useNavigate();
    const { t } = useTranslation();

    const [templates, setTemplates] = useState<TemplateItem[]>([]);
    const [users, setUsers] = useState<DBUser[]>([]);

    const [selectedTemplate, setSelectedTemplate] = useState<TemplateItem>();

    const getTemplates = async () => {
        try {
            const resData = await WppApi.getTemplate();
            const data = resData.data;
            setTemplates(data);
        } catch (err) {
            console.error(err);
        }
    };

    const getUsers = async () => {
        try {
            const tokenUsuario = sessionStorage.getItem("tokenUsuario");
            const config = { headers: { authorization: tokenUsuario } };

            const data: DBUser[] = await Api.GetUsuarios(config);
            setUsers(
                data.sort((a, b) =>
                    !a.telefone && b.telefone
                        ? 1
                        : a.telefone && !b.telefone
                        ? -1
                        : a.nome.localeCompare(b.nome)
                )
            );
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const expirationUsuario = sessionStorage.getItem("expirationUsuario");
        if (!expirationUsuario || new Date() > new Date(expirationUsuario)) {
            ClearSessionStorage();
            navegar("/login");
        }
    }, [navegar]);

    useEffect(() => {
        let refreshInterval: NodeJS.Timer;
        const stopRefresh = () => clearInterval(refreshInterval);

        try {
            getUsers();

            getTemplates();
            refreshInterval = setInterval(getTemplates, 10000);
        } catch {
            stopRefresh();
        }

        return () => {
            stopRefresh();
        };
    }, []);

    return (
        <div id="whatsappPage">
            <section className="templates">
                <div className="templatesHeader">
                    <h1 className="templatesTitle">{t("Modelos")}</h1>

                    <ModalCreateTemplate submit={getTemplates} />
                </div>
                <div className="templatesData">
                    {templates.map((t, i) => (
                        <TemplateCard
                            key={i}
                            template={t}
                            select={setSelectedTemplate}
                            selected={t.id === selectedTemplate?.id}
                        />
                    ))}
                </div>
            </section>

            <section id="templateModal">
                {templates.map((t) => (
                    <ModalSelectedTemplate
                        key={t.id!}
                        users={users}
                        template={t}
                        isSelected={!!selectedTemplate && t.id === selectedTemplate.id}
                        closeModal={() => setSelectedTemplate(undefined)}
                    />
                ))}
            </section>
        </div>
    );
}

export default Whatsapp;
